import { Controller } from "stimulus"

export default class extends Controller {
	static values = { autoOpen: Boolean, destroyOnClose: Boolean, target: String }

	connect() {
		if (this.autoOpenValue && this.element.nodeName === "DIALOG") this.element.showModal()
	}

	close() {
		this.element.close()
	}

	closeCallback() {
		if (!this.destroyOnCloseValue) return

		// basically if stated that way, we will remove the dialog, this should be the behavior used
		// for displaying videos from YouTube or any other kind of dialog where there's a chance that
		// something still continues to work after closing
		this.element.remove()
	}

	open() {
		if (!this.hasTargetValue) return

		const targetID = this.targetValue
		const target = document.getElementById(targetID)
		if (target && target.nodeName === "DIALOG") target.showModal()
	}
}
