import { Controller } from "stimulus"
import { useMatchMedia } from "stimulus-use"

export default class extends Controller {
	static targets = [ "navigation", "mainNavigation", "links", "submenus" ]
	static classes = [ "open", "close", "removal" ]

	initialize() {
		this.activeLink = this.mainNavigationTarget.querySelector(`.${this.navLinkSelected}`)
	}

	connect() {
		this.isHubVisible = false
		useMatchMedia(this, { mediaQueries: { mobile: "(max-width: 1023px)" } })
	}

	disconnect() {
		this.closeHub()
	}

	open() {
		this.navigationTarget.classList.remove(...this.closeClasses)
		this.navigationTarget.classList.add(...this.openClasses)
		document.body.classList.add("overflow-y-hidden")
		this.element.classList.add("top-nav--open")
	}

	close() {
		this.navigationTarget.classList.remove(...this.openClasses)
		this.navigationTarget.classList.add(...this.closeClasses)
		document.body.classList.remove("overflow-y-hidden")
		this.element.classList.remove("top-nav--open")
	}

	showHub(event) {
		this.closeHub()

		const link = event.target
		const target = this.submenusTargets.find((element) => element.id === link.dataset.submenu)
		if (!target) return

		// we need to hide any submenu that visible
		this.submenusTargets.forEach((element) => element.setAttribute("hidden", ""))

		event.preventDefault()
		target.removeAttribute("hidden")
		this.element.classList.add("top-nav--open")
		this.isHubVisible = true
		if (this.#isMobile()) this.linksTarget.classList.add("hidden")

		link.classList.add(this.navLinkSelected)
		// if we have an active link we need to remove active class
		if (this.activeLink) this.activeLink.classList.remove(this.navLinkSelected)
	}

	closeHub() {
		this.submenusTargets.forEach((element) => element.setAttribute("hidden", true))
		this.linksTarget.classList.remove("hidden")
		this.isHubVisible = false
		if (!this.#isMobile()) this.element.classList.remove("top-nav--open")

		const link = this.mainNavigationTarget.querySelector(`.${this.navLinkSelected}`)
		if (link) link.classList.remove(this.navLinkSelected)

		// if we have an active link we need to add active class
		if (this.activeLink) this.activeLink.classList.add(this.navLinkSelected)
	}

	onLeave() {
		this.closeHub()
	}

	onScroll() {
		if (!this.hasRemovalClass) return

		this.element.classList.toggle(this.removalClass, window.scrollY === 0)
	}

	isMobile() {
		if (this.isHubVisible) this.linksTarget.classList.add("hidden")
	}

	notMobile() {
		this.linksTarget.classList.remove("hidden")
	}

	#isMobile() {
		return window.matchMedia("(max-width: 1023px)").matches
	}

	get navLinkSelected() {
		return "top-nav__link--selected"
	}
}
