import { Controller } from "stimulus"
import * as Utils from "../../utils/common"

export default class extends Controller {
	static values = { url: String }

	initialize() {
		// we need to setup our debounce method, so that we can later use in when inputting
		// text in search input
		this.debounce = Utils.debounce(query => {
			fetch(`${this.urlValue}?term=${query}`, { headers: { Accept: "text/vnd.turbo-stream.html" } })
				.then(r => r.text())
				.then(html => Turbo.renderStreamMessage(html))
		}, 1000)
	}

	execute(event) {
		const input = event.target
		if (this.urlValue !== "") this.debounce(input.value)
	}
}
