import { Controller } from "stimulus"

export default class extends Controller {
	static targets = [ "step", "selector", "navigation", "startScreen", "steps" ]
	static classes = [ "selectorSelected" ]

	initialize() {
		this.step = 1
	}

	connect() {
		// we will only show the first step, and hide all the others
		this.stepTargets.slice(1).forEach((element) => {
			element.classList.add("hidden")
		})

		this.stepsTarget.setAttribute("hidden", true)
		this.#generateNavigationButtons()
		this.#showHiddenButtons()
		this.#checkSearchParams()
	}

	onNext() {
		this.#stepTo(this.step)
	}

	stepTo(event) {
		const target = event.currentTarget
		const index = Array.from(this.navigationTarget.childNodes).findIndex((element) => element === target)
		this.#stepTo(index)
	}

	showSteps(event) {
		event.preventDefault()
		this.#start()
	}

	#start() {
		if (this.hasStartScreenTarget) this.startScreenTarget.setAttribute("hidden", true)
		if (this.hasStepsTarget) this.stepsTarget.removeAttribute("hidden")
	}

	#stepTo(index) {
		const activeStep = this.stepTargets[this.step - 1]
		activeStep.classList.add("hidden")

		const targetStep = this.stepTargets[index]
		targetStep.classList.remove("hidden")

		this.step = index + 1
		this.#generateNavigationButtons()
	}

	// OK, we will look for URL params to see if a `step` is supplied, if it's supplied, we will
	// get it and check if we have that step, if we have we will move to that step, in the end we
	// replace URL to one without parameters
	#checkSearchParams() {
		const url = new URL(location.href)
		const searchParams = url.searchParams
		if (searchParams.has("step")) {
			const step = parseInt(searchParams.get("step"))
			if (step <= this.stepTargets.length) {
				this.#start()
				this.#stepTo(step - 1)
			}

			history.replaceState({}, "", `${url.origin}${url.pathname}`)
		}
	}

	#generateNavigationButtons() {
		this.navigationTarget.innerHTML = ""

		// basically the total number of navigation buttons are the total number of steps
		// plus 1 for the results page
		const totalButtons = this.stepTargets.length + 1
		for (let i = 0; i < totalButtons; i++) {
			const template = this.selectorTarget.content.cloneNode(true).firstChild
			template.innerText = i + 1
			if (i < this.step) {
				template.classList.add(...this.selectorSelectedClasses)
			} else {
				template.setAttribute("disabled", true)
			}
			this.navigationTarget.appendChild(template)
		}
	}

	#showHiddenButtons() {
		const buttons = this.element.querySelectorAll("button[hidden]")
		buttons.forEach((button) => button.removeAttribute("hidden"))
	}
}
