import { Controller } from "stimulus"

export default class extends Controller {
	static targets = [ "link", "content" ]
	static classes = [ "selected" ]

	connect() {
		if (!this.hasLinkTarget) return

		this.#handleButtonClick(this.linkTargets[0])
	}

	onClick(event) {
		event.preventDefault()
		this.#handleButtonClick(event.currentTarget)
	}

	#handleButtonClick(element) {
		this.#select(this.#idFromHref(element.href))
		element.classList.add(...this.selectedClasses)
		element.setAttribute("aria-pressed", true)
	}

	#select(id) {
		const content = this.contentTargets.find((content) => content.id === id )
		if (content) {
			this.#clearActiveTab()
			this.#hideContent()
			content.classList.remove("hidden")
		}
	}

	#clearActiveTab() {
		this.linkTargets.forEach((link) => {
			link.classList.remove(...this.selectedClasses)
			link.setAttribute("aria-pressed", false)
		})
	}

	#hideContent() {
		this.contentTargets.forEach((content) => content.classList.add("hidden"))
	}

	#idFromHref(href) {
		return new URL(href).hash.replace("#", "")
	}
}
