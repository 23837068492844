import { Controller } from "stimulus"

export default class extends Controller {
	connect() {
		if (this.#needsRefresh()) {
			this.#loadWeather()
		} else {
			Turbo.renderStreamMessage(this.weather.content)
		}
	}

	#loadWeather() {
		fetch("/weather", { headers: { Accept: "text/vnd.turbo-stream.html" } })
		.then(r => r.text())
		.then((html) =>
		{
			this.#update(html)
			Turbo.renderStreamMessage(html)
		})
	}

	#needsRefresh() {
		const weather = this.weather
		const updatedAt = weather.updatedAt
		return updatedAt === null || (updatedAt instanceof Date && isNaN(updatedAt.getTime())) || this.#timeAgo(updatedAt) >= 30
	}

	#timeAgo(date) {
		const now = new Date()
		const diff = Math.abs(date - now)
		return Math.floor((diff / 1000) / 60)
	}

	#update(content) {
		const weather = { updatedAt: new Date().toString(), content: content }
		localStorage.setItem("weather", JSON.stringify(weather))
	}

	get weather() {
		const weather = JSON.parse(localStorage.getItem("weather"))
		if (!weather) return { updatedAt: null, content: null }

		// we need to convert the store value to a date object so that
		// we can do math with it
		weather.updatedAt = new Date(weather.updatedAt)
		return weather
	}
}
