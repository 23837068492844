export function extractYouTubeVideoID(url) {
	const regex = /(?:youtube(?:-nocookie)?\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/
	const matches = regex.exec(url)
	if (!matches) return null
	return matches[matches.length - 1]
}

export function embedUrlForYouTube(url, options = {}) {
	const videoID = extractYouTubeVideoID(url)
	if (!videoID) return null

	// we will set some default options if they didn't get sent
	const params = new URLSearchParams(Object.assign({ rel: 0, modestbranding: 1 }, options))
	return new URL(`https://www.youtube-nocookie.com/embed/${videoID}?${params.toString()}`)
}

export function debounce(cb, delay = 250) {
	let timeout

	return (...args) => {
		clearTimeout(timeout)
		timeout = setTimeout(() => {
			cb(...args)
		}, delay)
	}
}

export function getMetaContent(name) {
	const meta = document.querySelector(`meta[name=${name}]`)
	return meta && meta.content
}

export function handleFormMethod(link) {
	// we will retrieve the method for link, if we don't receive one
	// we will skip the remaining code
	const method = link.dataset.method
	if (!method) return

	let formContent = `<input name="_method" value="${method}" type="hidden" />`

	const csrfToken = getMetaContent("csrf-token")
	const csrfParam = getMetaContent("csrf-param")
	if (csrfParam && csrfToken) formContent += `<input name='${csrfParam}' value='${csrfToken}' type='hidden' />`

	const form = document.createElement("form")
	form.method = "post"
	form.action = link.href
	form.target = link.target
	form.innerHTML = formContent
	form.style.display = "none"
	document.body.appendChild(form)

	form.submit()
}

export function toClipboard(content) {
	if (typeof content === "string") {
		const element = document.createElement("textarea")
		element.value = content
		element.setAttribute("readonly", "")
		element.style = { position: "absolute", left: "-9999px" }
		document.body.appendChild(element)
		element.select()
		document.execCommand("copy")
		document.body.removeChild(element)
	} else if (content.nodeName === "INPUT") {
		content.select()
		document.execCommand("copy")
	}
}

export function bytesToHuman(bytes) {
	if (bytes == 0) return "0 Byte"

	const sizes = ["Bytes", "KB", "MB", "GB", "TB"]
	const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))
	return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i]
}
