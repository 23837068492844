import { Controller } from "stimulus"

export default class extends Controller {
	initialize() {
		this.onTurboLoad = this.#onTurboLoad.bind(this)
	}

	connect() {
		document.addEventListener("turbo:load", this.onTurboLoad)
	}

	disconnect() {
		document.removeEventListener("turbo:load", this.onTurboLoad)
	}

	#onTurboLoad() {
		// basically we will check if a parameter called `jump_to` is available if we have the parameter
		// we will try to retrieve the element in parameter to view and update URL
		const url = new URL(window.location)
		const jumper = url.searchParams.get("jump_to")

		if (jumper === "") return

		// we got a jumper people, so we will try to retrieve the element and remove the parameter
		const target = document.getElementById(jumper)
		if (!target) return

		target.scrollIntoView()
		url.searchParams.delete("jump_to")
		url.hash = `#${jumper}`
		history.replaceState({}, "", url)
	}
}
