import { Controller } from "stimulus"

export default class extends Controller {
	static targets = [ "startDate", "endDate" ]

	connect() {
		this.startDateTarget.setAttribute('max', this.endDateTarget.value)
		this.endDateTarget.setAttribute('min', this.startDateTarget.value)
	}

	onStartDateChange() {
		const element = this.startDateTarget
		if (element.value === "" && element.dataset.resetTo) element.value = element.dataset.resetTo
		this.endDateTarget.setAttribute('min', element.value)
	}

	onEndDateChange() {
		this.startDateTarget.setAttribute('max', this.endDateTarget.value)
	}
}
