import { Controller } from "stimulus"
import { useIntersection, useMatchMedia } from "stimulus-use"

export default class extends Controller {
	static targets = [ "list", "track", "item" ]

	connect() {
		useMatchMedia(this, { mediaQueries: { landscape: '(orientation: landscape)' } })
		useIntersection(this)
	}

	appear() {
		this.#trackVisibilityCheck()
	}

	isLandscape() {
		this.#trackVisibilityCheck()
	}

	notLandscape() {
		this.#trackVisibilityCheck()
	}

	scroll() {
		// if we don't have one of the elements needed to display progress, we just leave the
		// function, because is not worth it continue
		if (!this.hasItemTarget || !this.hasListTarget) return

		const position = this.listTarget.scrollLeft / (this.listTarget.scrollWidth - this.listTarget.offsetWidth)
		const parent = this.itemTarget.parentNode
		const left = parent.offsetWidth * position
		this.itemTarget.style.left = `${Math.max(0, left - this.itemTarget.offsetWidth)}px`
	}

	#trackVisibilityCheck() {
		this.trackTarget.classList.toggle("hidden", this.listTarget.offsetWidth === this.listTarget.scrollWidth)
	}
}
