import { Controller } from "stimulus"
import { useClickOutside } from "stimulus-use"

export default class extends Controller {
	static targets = [ "button", "caret", "counter", "checkbox" ]
	static classes = [ "selected" ]

	connect() {
		useClickOutside(this)

		// some dropdown buttons can provide a `caret` target, that we will rotate based on
		// the button press
		if (this.hasCaretTarget) this.caretTarget.classList.add("transform", "transition")
		if (this.hasCheckboxTarget) {
			this.#setupCheckboxes()
			this.#checkSelection()
		}
	}

	clickOutside() {
		this.#close()
	}

	onToggle() {
		const isPressed = this.buttonTarget.getAttribute("aria-pressed") === "true"
		isPressed ? this.#close() : this.#open()
		if (this.hasCaretTarget) this.caretTarget.classList.toggle("rotate-180", !isPressed)
	}

	onCheckboxChange() {
		this.#setupCheckboxes()
	}

	onSelectAll(event) {
		event.stopImmediatePropagation()

		const selectAllCheckbox = event.target
		if (this.hasCheckboxTarget) {
			this.checkboxTargets.forEach((checkbox) => checkbox.checked = selectAllCheckbox.checked)
			// we need to trigger the change event so we will use the first check box target and trigger
			// the change event for it
			this.checkboxTargets[0].dispatchEvent(new Event("change", { bubbles: true }))
			this.#setupCheckboxes()
			this.#close()
		}
	}

	updateCounter() {
		this.#setupCheckboxes()

		const selectAll = this.element.querySelector("[form]")
		if (selectAll) selectAll.checked = false
	}

	reset() {
		// we should look for any input[type="radio"] and uncheck any that is checked
		const radios = this.element.querySelectorAll('input[type="radio"]')
		radios.forEach((radio) => {
			if (radio.checked) {
				radio.checked = false
				radio.dispatchEvent(new Event("change", { bubbles: true }))
			}
		})

		this.#setupCheckboxes()
		this.#close()
	}

	#open() {
		this.buttonTarget.setAttribute("aria-pressed", true)
	}

	#close() {
		this.buttonTarget.setAttribute("aria-pressed", false)
		if (this.hasCaretTarget) this.caretTarget.classList.remove("rotate-180")
	}

	#setupCheckboxes() {
		const total = this.checkboxTargets.length
		const checked = this.checkboxTargets.filter((checkbox) => checkbox.checked).length

		if (this.hasSelectedClass) {
			if (checked > 0) {
				this.element.classList.add(...this.selectedClasses)
			} else {
				this.element.classList.remove(...this.selectedClasses)
			}
		}

		if (this.hasCounterTarget) {
			if (checked > 0) {
				this.counterTarget.innerText = `(${checked})`
			} else {
				this.counterTarget.innerText = ""
			}
		}

		const selectAll = this.element.querySelector('[data-action="change->dropdown#onSelectAll"]')
		if (selectAll) selectAll.checked = checked === total
	}

	#checkSelection() {
		const total = this.checkboxTargets.length
		const checked = this.checkboxTargets.filter((c) => c.checked).length

		if (checked === total) {
			const selectAll = this.element.querySelector('[data-action="change->dropdown#onSelectAll"]')
			if (selectAll) selectAll.checked = true
		}
	}
}
