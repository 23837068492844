import { Controller } from "stimulus"

export default class extends Controller {
	initialize() {
		this.doLoad = this.#doLoad.bind(this)
	}

	connect() {
		this.#checkHash()
		window.addEventListener("turbo:load", this.doLoad)
	}

	disconnect() {
		window.removeEventListener("turbo:load", this.doLoad)

		// we need to retrieve the settings consent modal and remove it if it exists
		const settingsConsentModal = document.querySelector("#cc_div")
		if (settingsConsentModal) settingsConsentModal.remove()
	}

	showSettings(event) {
		event.preventDefault()
		this.#doLoad()
		this.cookieManager.showSettings()
	}

	#doLoad() {
		// and then we will configure the plugin
		this.cookieManager.run({
			current_lang: window.App.Locale,
			autoclear_cookies: true,
			page_scripts: true,
			use_rfc_cookie: true,
			gui_options: {
				consent_modal: {
					swap_buttons: true,
					layout: "cloud",
					position: "top center"
				}
			},
			onFirstAction: function(preferences) {
				// OK, basically we will check if the user accepted `functional` cookies, if accepted
				// and we have some `.permission` element in the page we will reload the page
				const permissionElements = document.querySelectorAll(".permission-required")
				if (permissionElements.length === 0) return
				if (preferences.accepted_categories.includes("functional")) window.location.reload()
			},
			onChange: function() {
				// we will check if we have any element that requires permission in the page and only
				// reload the page if that's true, no need to reload without reason
				const permissionElements = document.querySelectorAll(".permission-required")
				if (permissionElements.length === 0) return
				window.location.reload()
			},
			languages: {
				en: {
					consent_modal: {
						description: "Essential cookies ensure the proper operation of our website, functional cookies gives you a better experience, while tracking cookies allow us to understand how you interact with it. We need your consent to use them. <button type=\"button\" data-cc=\"c-settings\" class=\"cc-link\">Manage cookies</button>.",
						primary_btn: { text: "Accept all", role: "accept_all" },
						secondary_btn: { text: "Reject all", role: "accept_necessary" }
					},
					settings_modal: {
						title: "Cookie settings",
						save_settings_btn: "Save settings",
						accept_all_btn: "Accept all",
						reject_all_btn: "Reject all",
						close_btn_label: "Close",
						cookie_table_headers: [
							{ col1: "Name" },
							{ col2: "Expiration" },
							{ col3: "Description" }
						],
						blocks: [
							{
								title: "Cookie usage 📢",
								description: `We use cookies to ensure the website's basic functionalities and enhance your online experience. You can choose to opt in/out for each category at any time. Please read the full <a href=\"${window.App.Links.Privacy}\" class=\"cc-link\">cookie policy</a> for more details about cookies.`
							},
							{
								title: "Strictly necessary cookies",
								description: "These cookies are essential for the proper functioning of our website. Without these cookies, the website would not work properly.",
								toggle: { value: "necessary", enabled: true, readonly: true }
							},
							{
								title: "Functional cookies",
								description: "These cookies provide features beyond the essential functionality such as video playback or maps visualization.",
								toggle: { value: "functional", enabled: false, readonly: false },
								cookie_table: [
									{ col1: "AEC", col2: "6 months", col3: "Ensure that requests within a browsing session are made by the user, and not by other sites." },
									{ col1: "NID", col2: "6 months", col3: "Used to show Google ads in Google services for signed-out users." },
									{ col1: "CONSENT", col2: "13 months", col3: "Used to store a user’s state regarding their cookies choices." },
									{ col1: "SOCS", col2: "13 months", col3: "Used to store a user’s state regarding their cookies choices." }
								]
							},
							{
								title: "Analytics and targeting cookies",
								description: "These cookies collect information about how you use the website, which pages you visited and which links you clicked on. All of the data is anonymous and cannot be used to identify you.",
								toggle: { value: "analytics", enabled: false, readonly: false },
								cookie_table: [
									{ col1: "_ga", col2: "1 year", col3: "To store and count pageviews." },
									{ col1: "_gid", col2: "1 day", col3: "To store and count pageviews." },
									{ col1: "^_gat", col2: "1 minute", col3: "To read and filter requests from bots.", is_regex: true }
								]
							},
							{
								title: "More information",
								description: "For any queries in relation to our policy on cookies and your choices, please <a class=\"cc-link\" href=\"mailto:atl@visitlisboa.com\">contact us</a>.",
							}
						]
					}
				},
				"pt-pt": {
					consent_modal: {
						description: "Cookies essenciais permitem o correto funcionamento do site, os cookies funcionais permitem ter uma melhor experiência, já os cookies analíticos permite-nos perceber como interage com o site. Para tal precisamos do seu consentimento para os usar. <button type=\"button\" data-cc=\"c-settings\" class=\"cc-link\">Gerir cookies</button>.",
						primary_btn: { text: "Aceitar todos", role: "accept_all" },
						secondary_btn: { text: "Rejeitar todos", role: "accept_necessary" }
					},
					settings_modal: {
						title: "Definições de Cookies",
						save_settings_btn: "Guardar",
						accept_all_btn: "Aceitar todos",
						reject_all_btn: "Rejeitar todos",
						close_btn_label: "Fechar",
						cookie_table_headers: [
							{ col1: "Nome" },
							{ col2: "Duração" },
							{ col3: "Descrição" }
						],
						blocks: [
							{
								title: "Utilização de cookies 📢",
								description: `Utilizamos cookies para garantir o funcionamento necessário do site e para melhor a experiência do utilizador. Poderá optar por aceitar or rejeitar cada categoria a qualquer momento. Por favor leia a <a href=\"${window.App.Links.Privacy}\" class=\"cc-link\">política de cookies</a> completa para mais detalhes sobre cookies.`
							},
							{
								title: "Cookies estritamente necessários",
								description: "Estes cookies são essenciais para o funcionamento correto do site. Sem estes cookies, o site pode deixar de funcionar corretamente.",
								toggle: { value: "necessary", enabled: true, readonly: true }
							},
							{
								title: "Cookies funcionais",
								description: "Estes cookies permitem funcionalidades para além das essenciais tais como visualização de vídeos ou mapas.",
								toggle: { value: "functional", enabled: false, readonly: false },
								cookie_table: [
									{ col1: "AEC", col2: "6 meses", col3: "Certifica-se que todos os pedidos durante uma sessão de navegação são feitos pelo utilizador e não por outros sites." },
									{ col1: "NID", col2: "6 meses", col3: "Usado para apresentar ads em serviços da Google para utilizadores sem sessão iniciada." },
									{ col1: "CONSENT", col2: "13 meses", col3: "Usado para guardar o estado do utilizador em relação às suas escolhas de cookies." },
									{ col1: "SOCS", col2: "13 meses", col3: "Usado para guardar o estado do utilizador em relação às suas escolhas de cookies." }
								]
							},
							{
								title: "Cookies analíticos e de segmentação",
								description: "Estes cookies recolhem informação sobre a forma como utiliza o site, que páginas visitou e links clicados. Toda a informação é anónima e não poderá ser usada para o identificar.",
								toggle: { value: "analytics", enabled: false, readonly: false },
								cookie_table: [
									{ col1: "_ga", col2: "1 ano", col3: "Para armazenar e contar visualizações de página." },
									{ col1: "_gid", col2: "1 dia", col3: "Para armazenar e contar visualizações de página." },
									{ col1: "^_gat", col2: "1 minute", col3: "Para ler e filtra solicitações de bots.", is_regex: true }
								]
							},
							{
								title: "Mais informação",
								description: "Para qualquer dúvida sobre a nossa política de cookies e as suas escolhas, por favor <a class=\"cc-link\" href=\"mailto:atl@visitlisboa.com\">contate-nos</a>."
							}
						]
					}
				}
			}
		})
	}

	#checkHash() {
		if (window.location.hash === "#CookieSettings") {
			this.#doLoad()
			this.cookieManager.showSettings()
		}
	}

	get cookieManager() {
		if (!this.cc) this.cc = initCookieConsent()

		return this.cc
	}
}
