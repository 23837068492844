import { Controller } from "stimulus"
import MapManager from "../../utils/map_manager"
import * as Utils from "../../utils/common"

export default class extends Controller {
	static targets = [ "map", "place", "counter", "locations", "categories", "term" ]
	static values = { linkIcon: String, directionsIcon: String }

	initialize() {
		this.colors = ["#00684d", "#004e3a", "#003426", "#c26a68", "#91504e", "#613534"]
		this.places = []
		this.debounce = Utils.debounce(() => {
			this.filter()
		}, 250)
	}

	connect() {
		this.map = new MapManager(this.mapTarget)
		this.#prepareContent()
		this.filter()
	}

	onInput() {
		this.debounce()
	}

	filter() {
		this.map.resetMap()
		let places = this.places

		const term = this.termTarget.value
		if (term !== "") places = places.filter((place) => place.name.toLowerCase().indexOf(term.toLowerCase()) >= 0)

		const categories = new Set(this.#selectedCategories())
		places = places.filter((place) => categories.intersection(new Set(place.categories)).size > 0)

		const locations = new Set(this.#selectedLocations())
		places = places.filter((place) => locations.intersection(new Set(place.locations)).size > 0)

		this.#showMapPins(places)
	}

	#prepareContent() {
		this.placeTargets.forEach((place) => {
			this.places.push({
				name: place.innerText,
				lng: parseFloat(place.dataset.lng),
				lat: parseFloat(place.dataset.lat),
				address: place.dataset.address,
				categories: JSON.parse(place.dataset.categories),
				locations: JSON.parse(place.dataset.locations),
				url: place.dataset.url
			})
		})

		this.#showMapPins(this.places)
	}

	#showMapPins(places) {
		places.forEach((place) => {
			this.map.addDirectionMarker(place, place.name[0].toUpperCase(), place.url, this.#colorsForCategory(place.categories[0]), 0, this.linkIconValue, this.directionsIconValue)
		})

		this.counterTarget.innerText = places.length
	}

	#selectedLocations() {
		const selected = this.locationsTargets.filter((location) => location.checked)
		return selected.map((location) => parseInt(location.value))
	}

	#selectedCategories() {
		let categories = []
		const selected = this.categoriesTargets.filter((category) => category.checked)
		selected.forEach((category) => {
			const options = JSON.parse(category.dataset.categories)
			categories = categories.concat(options)
		})

		return categories
	}

	#colorsForCategory(category) {
		const colors = {}
		this.categoriesTargets.forEach((category, index) => {
			colors[parseInt(category.value)] = this.colors[index % this.colors.length]
		})

		const match = this.categoriesTargets.find((element) => JSON.parse(element.dataset.categories).includes(category))
		return colors[parseInt(match.value)]
	}
}
