import { Controller } from "stimulus"

export default class extends Controller {
	static targets = [ "link" ]

	connect() {
		if (this.hasLinkTarget) this.element.classList.add("cursor-pointer")
	}

	onClick(event) {
		if (!this.hasLinkTarget) return

		// ok, if we have more links inside the card, we want them to still be clickable, however
		// for the target `link` we don't want that happen, because it would be a double click, so
		// we check if the target in `event` is our element or not
		if (this.linkTarget !== event.target) {
			// now we will do a little trick that we have taken from here:
			// https://css-tricks.com/block-links-the-search-for-a-perfect-solution/ where we control
			// if we can click in the card based on possible selected text
			const isTextSelected = window.getSelection().toString()
			if (!isTextSelected) Turbo.visit(this.linkTarget.href)
		}
	}
}
