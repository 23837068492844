import { Controller } from "stimulus"
import { useIntersection } from "stimulus-use"

export default class extends Controller {
	static targets = [ "list", "previous", "next" ]

	connect() {
		// basically we will do a dirty trick here, which is set `scrollbar-width` to `none` and
		// this way for modern browsers we make list scroll disappear
		this.listTarget.style.scrollbarWidth = "none"
		this.#checkButtons()

		useIntersection(this)
	}

	appear() {
		this.#checkButtons()
	}

	previous() {
		const desiredLocation = this.listTarget.scrollLeft - this.#getProgressJump()
		this.listTarget.scrollTo(desiredLocation, 0)
	}

	next() {
		const desiredLocation = this.listTarget.scrollLeft + this.#getProgressJump()
		this.listTarget.scrollTo(desiredLocation, 0)
	}

	scroll() {
		this.#checkButtons()
	}

	#checkButtons() {
		this.previousTarget.classList.toggle("lg:block", this.listTarget.scrollLeft !== 0)
		this.nextTarget.classList.toggle("lg:block", !this.#hasReachedEnd())
	}

	#hasReachedEnd() {
		return (this.listTarget.scrollLeft + this.listTarget.offsetWidth) === this.listTarget.scrollWidth
	}

	#getProgressJump() {
		const element = this.listTarget.children[0]
		return element.clientWidth
	}
}
