import { Controller } from "stimulus"
import Swal from "sweetalert2"

export default class extends Controller {
	static values = { type: String }

	initialize() {
		this.toast = Swal.mixin({ toast: true, position: "top", showConfirmButton: false, timer: 5000 })
	}

	connect() {
		this.#showMessage()
	}

	#showMessage() {
		let type = this.typeValue
		this.toast.fire({
			icon: type === "alert" ? "error" : "success",
			title: this.element.innerHTML
		})
	}
}
