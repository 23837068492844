import { Controller } from "stimulus"
import { useMatchMedia, useMutation, useTargetMutation } from "stimulus-use"
import * as Utils from "../../utils/common"

export default class extends Controller {
	static targets = [ "dialog", "container", "filters", "trigger", "advancedButton", "clear" ]
	static values = { singleResult: String, multipleResults: String, mobileDialogTitle: String }

	initialize() {
		this.debounce = Utils.debounce(() => {
			this.element.requestSubmit()
		}, 1000)

		this.onSubmitEnd = this.#onSubmitEnd.bind(this)
		this.clearedForm = false

		// we will store the dialog title for future reference, because for mobile we need
		// to show a different title
		if (this.hasDialogTarget) {
			this.dialogTitle = this.dialogTarget.querySelector('[data-dialog="heading"]').innerText
		}

		this.#handleClearButton()
	}

	connect() {
		useMatchMedia(this, { mediaQueries: { mobile: "(max-width: 1023px)" } })
		document.addEventListener("turbo:submit-end", this.onSubmitEnd)

		// check if parameters are applied if that's true we should navigate to the results section
		this.#doScrollIfNeeded()

		// we will check if we have an `advancedButton` and then we will check if we have any content
		// in the dialog, if we don't have we will just hide the advanced button
		this.#advancedButtonCheck()

		useTargetMutation(this)
	}

	disconnect() {
		document.removeEventListener("turbo:submit-end", this.onSubmitEnd)
	}

	onChange() {
		this.debounce()
	}

	doSubmit(event) {
		event.preventDefault()
		const button = event.currentTarget
		const dialog = button.closest("dialog")
		if (dialog) dialog.close()
	}

	reset() {
		// we only want to deal with `INPUT` elements
		const elements = [...this.element.elements].filter((element) => element.nodeName === "INPUT")
		elements.forEach((element) => {
			// OK, so we will check if there's a `data-` attribute named clearable, we don't really
			// care what is the value, if the data attribute exists we just skip clearing the value
			// of the element
			if (!element.dataset.resetTo) {
				if (element.type === "checkbox" || element.type === "radio") {
					element.checked = false
				} else {
					element.value = null
				}
			} else if (element.type !== "checkbox" || element.type !== "radio") {
				element.value = element.dataset.resetTo
			}
		})

		// additionally we need to trigger a change event on any dropdown element in the form
		// because some of them will have counters that need to be updated
		const dropdowns = this.element.querySelectorAll('[data-controller="dropdown"]')
		dropdowns.forEach((dropdown) => {
			dropdown.dispatchEvent(new Event("filters:change"))
		})

		this.clearedForm = true
		this.element.requestSubmit()
	}

	isMobile() {
		// sometimes we don't want a dialog element because the inline filters are enough and
		// we don't have need to move filters to the dialog
		if (!this.hasDialogTarget) return

		if (this.hasFiltersTarget && !this.dialogTarget.contains(this.filtersTarget)) {
			// now we will retrieve the content section from the dialog so that we can prepend
			// the filters target inside of it
			const content = this.dialogTarget.querySelector('[data-target="content"]')
			if (!content) return

			content.prepend(this.filtersTarget)
		}

		if (this.mobileDialogTitleValue !== "") this.#setDialogTitle(this.mobileDialogTitleValue)
	}

	notMobile() {
		if (this.hasContainerTarget && !this.containerTarget.contains(this.filtersTarget)) {
			this.containerTarget.appendChild(this.filtersTarget)
		}

		this.#setDialogTitle(this.dialogTitle)

		// OK, when we are swapping between mobile/desktop we will check if the dialog modal
		// is open, and close it if not content is inside dialog content section
		if(this.hasDialogTarget && this.dialogTarget.open) {
			const content = this.dialogTarget.querySelector('[data-target="content"]')
			if (content && content.children.length === 0) this.dialogTarget.close()
		}
	}

	dialogTargetChanged() {
		this.#advancedButtonCheck()
	}

	#doScrollIfNeeded() {
		setTimeout(() => {
			const url = new URL(location.href)
			if (url.searchParams.size === 0) return

			const scrollElement = document.getElementById("results")
			if (!scrollElement) return

			scrollElement.scrollIntoView()
		}, 100)
	}

	#onSubmitEnd() {
		const searchParams = new URLSearchParams(new FormData(this.element))
		const url = new URL(`${location.origin}${location.pathname}`)

		if (this.clearedForm) {
			this.clearedForm = false
		} else {
			url.search = searchParams.toString()
		}

		// we will use `Turbo.navigator.history.push` so that the user have the chance of have the
		// query params to share with someone else
		Turbo.navigator.history.push(url)

		if (!this.hasTriggerTarget) return
		if (!event.detail.fetchResponse) return

		const response = event.detail.fetchResponse.response
		const totalCount = response.headers.get("total-count")
		if (totalCount === null) return

		if (parseInt(totalCount) === 1) {
			this.triggerTarget.innerText = this.singleResultValue
		} else {
			this.triggerTarget.innerText = this.multipleResultsValue.replace("%x", totalCount)
		}

		this.#handleClearButton()
	}

	#setDialogTitle(title) {
		if (!this.hasDialogTarget) return

		const heading = this.dialogTarget.querySelector('[data-dialog="heading"]')
		if (heading) heading.innerText = title
	}

	#advancedButtonCheck() {
		if (!this.hasAdvancedButtonTarget) return

		const content = this.dialogTarget.querySelector('[data-target="content"]')
		if (!content) return

		const nodes = content.querySelectorAll("[data-empty]")
		if (nodes.length > 0) {
			this.advancedButtonTarget.setAttribute("hidden", "hidden")
		} else {
			this.advancedButtonTarget.removeAttribute("hidden")
		}
	}

	#handleClearButton() {
		if (!this.hasClearTarget) return

		if (this.#hasSearchActive()) {
			this.clearTarget.removeAttribute("hidden")
		} else {
			this.clearTarget.setAttribute("hidden", "hidden")
		}
	}

	#hasSearchActive() {
		const url = new URL(location)
		return url.searchParams.size > 0
	}
}
