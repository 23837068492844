/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import "@hotwired/turbo-rails"
import "controllers/application"
import "vanilla-cookieconsent"

document.addEventListener("turbo:before-stream-render", (event) => {
	const stream = event.target
	if (stream.target === "results") {
		const element = document.getElementById("results")
		if (element) {
			const isReduced = window.matchMedia("(prefers-reduced-motion: reduce)").matches
			element.scrollIntoView( { behavior: isReduced ? "instant" : "smooth" } )
		}
	}
})

document.addEventListener("turbo:load", () => {
	document.documentElement.classList.add("scroll-smooth", "motion-reduce:scroll-auto")
})

document.addEventListener("turbo:before-visit", () => {
	document.documentElement.classList.remove("scroll-smooth", "motion-reduce:scroll-auto")
})

document.addEventListener("turbo:frame-render", () => {
	const target = document.getElementById("results")
	if (target) target.scrollIntoView()
})
