import { Controller } from "stimulus"
import { useIntersection } from "stimulus-use"

export default class extends Controller {
	static targets = ["track", "image", "template", "controls", "previous", "next", "info"]
	static values = { autoPlay:Boolean, duration: Number }

	initialize() {
		this.timeoutID = null
		this.currentImage = 0
	}

	connect() {
		if (!this.hasControlsTarget && this.hasTemplateTarget) this.#buildControls()
		this.imageTargets.forEach((item) => useIntersection(this, { element: item, root: this.trackTarget, threshold: 0.9 }))

		if (this.autoPlayValue) this.#setupAutoplay()
		useIntersection(this)
	}

	appear(entry) {
		const target = entry.target
		const elementIndex = this.imageTargets.findIndex((element) => element === target)
		if (elementIndex !== -1) this.currentImage = elementIndex

		this.#updateInfo()
		this.#checkControls()

		// now we check if we have autoplay and we will only continue if we have autoplay
		// and we are dealing with the current element, if that's true we will setup autoplay
		if (!this.autoPlayValue) return
		if (entry.target !== this.element) return

		this.#setupAutoplay()
	}

	disappear(entry) {
		// we don't need to do anything if autoplay is not defined
		if (!this.autoPlayValue) return
		if (entry.target !== this.element) return

		clearTimeout(this.timeoutID)
	}

	previous() {
		const index = this.currentImage - 1
		if (index >= 0) this.#moveTo(index)
	}

	next() {
		const index = this.currentImage + 1
		if (index <= this.imageTargets.length) this.#moveTo(index)
	}

	onEnter() {
		if (!this.autoPlayValue) return

		clearTimeout(this.timeoutID)
	}

	onLeave() {
		if (!this.autoPlayValue) return

		this.#setupAutoplay()
	}

	onVisibilityChange() {
		if (!this.autoPlayValue) return

		if (document.hidden) {
			clearTimeout(this.timeoutID)
		} else {
			this.#setupAutoplay()
		}
	}

	#buildControls() {
		// because if the user is navigating using back/forward buttons of the browser, we shouldn't
		// create duplicated controls, other alternative would be to remove controls on `disconnect`
		if (this.hasControlsTarget) return

		this.element.insertAdjacentHTML("beforeend", this.templateTarget.innerHTML)
		this.#updateInfo()
		this.#checkControls()
	}

	#moveTo(position) {
		const imageElement = this.imageTargets[position]
		this.trackTarget.scrollLeft = imageElement.offsetLeft
	}

	#checkControls() {
		const index = this.currentImage
		if (this.hasPreviousTarget) this.previousTarget.toggleAttribute("disabled", index === 0)
		if (this.hasNextTarget) this.nextTarget.toggleAttribute("disabled", index === this.imageTargets.length - 1)
	}

	#updateInfo() {
		if (!this.hasInfoTarget) return

		this.infoTarget.innerHTML = `${this.currentImage + 1} / ${this.imageTargets.length}`
	}

	#setupAutoplay() {
		// just to avoid any problem, we will clear any possible defined timeout before setting
		// the new one
		clearTimeout(this.timeoutID)
		this.timeoutID = setTimeout(
			() => {
				// if we are in the last image we should first move the carousel to first image and only
				const isLast = this.currentImage + 1 === this.imageTargets.length
				if (isLast) {
					this.trackTarget.scrollTo({ left: 0 })
				} else {
					this.next()
				}

				this.#setupAutoplay()
			},
			this.duration
		)
	}

	get duration() {
		// if we don't have a duration value defined (or is less than 1 second) we will assume a 15s
		// wait before changing between slides, it's a reasonable value
		if (this.durationValue < 1000) return 15000

		return this.durationValue
	}
}
