import { Controller } from "stimulus"

export default class extends Controller {
	static targets = [ "input", "button", "icon" ]
	static values = { pressedIcon: String }

	connect() {
		this.buttonTarget.removeAttribute("hidden")
		this.defaultIcon = this.iconTarget.querySelector("use").href.baseVal
	}

	show() {
		this.inputTarget.setAttribute("type", "text")
		this.buttonTarget.setAttribute("aria-pressed", "true")
		if (this.hasPressedIconValue) this.iconTarget.innerHTML = `<use href="${this.pressedIconValue}">`
	}

	hide() {
		this.inputTarget.setAttribute("type", "password")
		this.buttonTarget.setAttribute("aria-pressed", "false")
		this.iconTarget.innerHTML = `<use href="${this.defaultIcon}">`
	}
}
