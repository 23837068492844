import { Controller } from "stimulus"

export default class extends Controller {
	static targets = [ "track", "item", "nav" ]
	static classes = [ "navSelected" ]

	initialize() {
		this.currentNav = null
		this.onPopState = this.#onPopState.bind(this)
	}

	connect() {
		// we want to listen to pop state event and change any hash if it's in our controller the
		// referenced element
		window.addEventListener("popstate", this.onPopState)

		// we will start by setting all items to not be focusable by using `inert` attribute
		this.itemTargets.forEach((item) => item.setAttribute("inert", "") )

		// OK, we will set the first element in item targets as the default selected, however, we
		// will check if the URL as an hash and if it has we will check if we can find that element
		// in our list and show it if needed
		if (this.hasNavTarget) {
			this.#select(this.navTargets[0], false)

			// now we check if there's a hash in the URL
			const hash = window.location.hash.trim()
			if (hash !== "") {
				const element = this.navTargets.find((element) => element.href.includes(hash))
				if (element) this.#select(element, false)
			}
		}
	}

	disconnect() {
		window.removeEventListener("popstate", this.onPopState)
	}

	onClick(event) {
		this.#select(event)
	}

	moveNext() {
		const desiredIndex = this.navIndex + 1
		const targetIndex = desiredIndex === this.navTargets.length ? 0 : desiredIndex
		this.#select(this.navTargets[targetIndex])
	}

	movePrevious() {
		const desiredIndex = this.navIndex - 1
		const targetIndex = desiredIndex < 0 ? this.navTargets.length - 1 : desiredIndex
		this.#select(this.navTargets[targetIndex])
	}

	#select(source, hash = true) {
		const element = source.type === "click" ? source.currentTarget : source
		const event = source.type === "click" ? source : null
		const href = element.href

		// now we set the hash in the URL to allow users to directly share a specific element
		if (hash) this.#setHash(href)

		// we will prevent default behavior, mainly because if the user is not in the right place
		// a jump can occur and we don't want that to happen if javascript is available, still we will
		// update the location and set the hash so that the user can share a given URL
		if (event) event.preventDefault()

		// we need to strip any possible hash `#` because the DOM target will not have that
		// in the ID so before filter we need to check that
		const id = new URL(href).hash.replace("#", "")

		// we will start by moving to the requested item, no animations, nothing, just a plain
		// jump from one to another
		this.#moveTo(id)

		// now we need to remove the any possible active/selected trigger and then apply to
		// this one the active/selected state
		if (this.hasNavSelectedClass) this.#selectNav(id)
	}

	#moveTo(id) {
		const trackElement = this.trackTarget
		const itemElement = this.itemTargets.find((element) => element.id === id)
		if (itemElement) {
			// we will move in the track element using track and item offset calculation
			trackElement.scrollLeft = itemElement.offsetLeft - trackElement.offsetLeft

			// now we need to set attribute `inert` for item target elements
			this.itemTargets.forEach((element) => element.setAttribute("inert", "") )
			// finally we remove `inert` from current item element because we want to allow focus
			// inside of that element
			itemElement.removeAttribute("inert")
		}
	}

	#selectNav(id) {
		// no need to do anything if don't have a `navSelected` class defined
		if (!this.hasNavSelectedClass) return

		// first we remove any element that have the `navSelected` class
		this.navTargets.forEach((element) => element.classList.remove(...this.navSelectedClasses))

		// now we will find the requested ID and mark as selected
		const navElement = this.navTargets.find((element) => new URL(element.href).hash === `#${id}`)
		if (navElement) {
			navElement.classList.add(...this.navSelectedClasses)
			this.currentNav = navElement
		}
	}

	#setHash(href) {
		const url = new URL(href)
		if (url.hash !== "") history.pushState(history.state, "", url.hash)
	}

	#onPopState() {
		const url = new URL(location.href)
		// OK, we just need the ID so we remove any # there, if we don't have any value in
		// the hash we just stop doing things
		const id = url.hash.replace("#", "")
		if (id === "") return

		// next, we need to find the element, because there's a chance that the ID is not from
		// any item for this controller, if we don't find any item we just stop
		const element = this.itemTargets.find((item) => item.id === id)
		if (!element) return

		// if we have found the element, we will move to that element and add the selected class
		// to the nav if it's defined
		this.#moveTo(element.id)
		if (this.hasNavSelectedClass) this.#selectNav(element.id)
	}

	get navIndex() {
		return this.navTargets.findIndex((element) => element === this.currentNav)
	}
}
