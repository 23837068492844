class MapManager {
	constructor(map, mapCenter = { lat: 38.7381814, lng: -9.153746 }) {
		this._mapElement = typeof map === 'string' ? document.querySelector(map) : map
		this._mapCenter = mapCenter
		this._markers = []
		this._lines = null
		this._lastWindow = null
		// because we will not know if Google Maps is already loaded or not, we will
		// set a interval that will check for the presence of Google Maps, we should stop checking and
		// cancel everything have a few trials

		// for now we will just hope for the best
		this.initializeMap()
	}

	initializeMap() {
		this._map = new google.maps.Map(this._mapElement, { center: this._mapCenter, zoom: 13 })
	}

	addDirectionMarker(location, label, url, color, zIndex = 0, linkIcon = null, directionsIcon = null) {
		let marker = this._createMarker(location, this._circleMarker(color), label, zIndex)
		this._createDirectionInfoWindow(location, marker, url, linkIcon, directionsIcon)
		this._markers.push(marker)
		return marker
	}

	resetMap() {
		if (this._lines) {
			this._lines.setMap(null)
		}

		this._markers.forEach((marker) => marker.setMap(null))
		this._markers = []
	}

	showInfoFor(id) {
		let marker = this._markers.find((marker) => marker.id === id)
		if (marker) {
			google.maps.event.trigger(marker, 'click')
		}
	}

	get markers() {
		return this._markers
	}

	getMap() {
		return this._map
	}

	_createMarker(location, template, label = null, zIndex = 1) {
		let marker = new google.maps.Marker({
			id: location.id,
			title: location.name,
			position: { lat: location.lat, lng: location.lng },
			map: this._map,
			zIndex: zIndex,
			icon: {
				url: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(template),
				origin: new google.maps.Point(0, 0),
				anchor: new google.maps.Point(15, 15),
				size: new google.maps.Size(30, 30)
			}
		})

		if (label) {
			marker.setLabel({ color: '#fbf9f9', fontSize: '12px', fontWeight: '600', text: label })
		}

		return marker
	}

	_defaultMarker() {
		return '<svg width="30" height="30" version="1.1" xmlns="http://www.w3.org/2000/svg"><path fill="#f9d100" d="M 22.046875 2.925781 C 20.097656 0.976562 17.742188 0 14.984375 0 C 12.226562 0 9.871094 0.976562 7.921875 2.925781 C 5.96875 4.878906 4.996094 7.230469 4.996094 9.988281 C 4.996094 11.40625 5.210938 12.570312 5.636719 13.480469 L 12.761719 28.582031 C 12.953125 29.011719 13.257812 29.351562 13.667969 29.597656 C 14.078125 29.84375 14.515625 29.96875 14.984375 29.96875 C 15.453125 29.96875 15.890625 29.84375 16.300781 29.597656 C 16.710938 29.351562 17.019531 29.011719 17.226562 28.582031 L 24.328125 13.480469 C 24.757812 12.570312 24.972656 11.40625 24.972656 9.988281 C 24.972656 7.230469 24 4.878906 22.046875 2.925781 Z M 18.515625 13.519531 C 17.539062 14.496094 16.363281 14.984375 14.984375 14.984375 C 13.605469 14.984375 12.429688 14.496094 11.453125 13.519531 C 10.476562 12.546875 9.988281 11.367188 9.988281 9.988281 C 9.988281 8.609375 10.476562 7.433594 11.453125 6.457031 C 12.429688 5.484375 13.605469 4.996094 14.984375 4.996094 C 16.363281 4.996094 17.539062 5.484375 18.515625 6.457031 C 19.492188 7.433594 19.980469 8.609375 19.980469 9.988281 C 19.980469 11.367188 19.492188 12.546875 18.515625 13.519531 Z M 18.515625 13.519531" /></svg>'
	}

	_circleMarker(color) {
		if (!color) {
			color = '#f9d100'
		}
		return `<svg width="30" height="30" version="1.1" xmlns="http://www.w3.org/2000/svg"><circle fill="${color}" cx="15" cy="15" r="15" /></svg>`
	}

	_createInfoWindow(location, marker) {
		let self = this
		let infoWindow = new google.maps.InfoWindow({ content: `<h4>${location.name}</h4><p>${location.address}</p>` })

		marker.addListener('click', function() {
			if (self._lastWindow) {
				self._lastWindow.close()
			}

			infoWindow.open(self._map, marker)
			self._lastWindow = infoWindow
		})
	}

	_createDirectionInfoWindow(location, marker, url, linkIcon = null, directionsIcon = null) {
		let self = this
		let link = url ? `<a href="${url}"><img src="${linkIcon}" alt="" /></a>` : ''
		let directions = `<p><a target="_blank" href="https://www.google.pt/maps/dir/${location.lat},${location.lng}"><img src="${directionsIcon}" alt="" /></a></p>`
		let infoWindow = new google.maps.InfoWindow({
			content: `<div class="map-info"><h2>${location.name}${link}</h2><p>${location.address}</p>${directions}</div>`
		})

		marker.addListener('click', function() {
			if (self._lastWindow) {
				self._lastWindow.close()
			}

			infoWindow.open(self._map, marker)
			self._lastWindow = infoWindow
		})
	}
}

export default MapManager
