import { Controller } from "stimulus"

export default class extends Controller {
	static targets = [ "option", "map" ]
	static values = { selected: Array }

	initialize() {
		this.onMapClick = this.#onMapClick.bind(this)
	}

	connect() {
		this.#setupMap()
		this.#select(this.selectedValue)
		if (this.map) {
			const mapLocations = this.map.querySelectorAll('[data-id]')
			mapLocations.forEach((location) => location.addEventListener("click", this.onMapClick))
		}
	}

	disconnect() {
		if (this.map) {
			const mapLocations = this.map.querySelectorAll('[data-id]')
			mapLocations.forEach((location) => location.removeEventListener("click", this.onMapClick))
		}
	}

	onSelect(event) {
		const input = event.currentTarget
		if (!input) return

		let locations = []
		if (input.tagName === "SELECT") {
			locations = Array.from(input.options).filter((option) => option.selected).map((option) => option.value)
		} else if (input.dataset.locations) {
			locations = JSON.parse(input.dataset.locations)
		} else {
			return
		}
		this.#select(locations)
	}

	#select(locations) {
		this.#deselectAll()

		// now that we already handled the previous selections, we will select the new ones
		locations.forEach((location) => {
			const element = this.map.querySelector(`[data-id="${location}"]`)
			if (element) element.classList.add(this.selectedClass)
		})
	}

	#onMapClick(event) {
		const target = event.currentTarget
		// we don't want to do nothing if the click item does not have the class `cursor-pointer`
		if (!target.classList.contains("cursor-pointer")) return

		this.#deselectAll()

		const id = target.dataset.id
		const inputs = this.element.querySelectorAll("[data-locations]")
		inputs.forEach((input) => {
			const locations = JSON.parse(input.dataset.locations)
			if (locations.includes(id)) input.checked = true
		})

		target.classList.add(this.selectedClass)
	}

	#deselectAll() {
		// first we will remove any selected locations
		const selected = this.map.querySelectorAll(`.${this.selectedClass}`)
		if (selected.length > 0) selected.forEach((element) => element.classList.remove(this.selectedClass))
	}

	#setupMap() {
		let availableLocations = []
		// first we gather all the available locations defined
		this.optionTargets.forEach((option) => {
			availableLocations = availableLocations.concat(JSON.parse(option.dataset.locations))
		})

		// then we will remove class `cursor-pointer` from any `[data-id]` from SVG map that is not
		// present in the `availableLocations` array
		this.map.querySelectorAll('[data-id]').forEach((item) => {
			if (!availableLocations.includes(item.dataset.id)) item.classList.remove("cursor-pointer")
		})
	}

	get selectedClass() {
		return "locations-map__selected"
	}

	get map() {
		return this.mapTarget.querySelector("svg")
	}
}
