import { Controller } from "stimulus"

export default class extends Controller {
	static targets = [ "dialog" ]

	onClick(event) {
		const target = event.target

		// we don't do anything if for some reason the dialog target is not available
		if (!this.hasDialogTarget) return

		// we expect to have a `data-` attribute to set the image URL and additionally a label
		// that we will make it work as a caption for the image
		if (!target.dataset.url) return

		this.contentElement.innerHTML = ""
		this.#buildImage(target.dataset)
		this.dialogTarget.showModal()
	}

	#buildImage(data) {
		const figureElement = document.createElement("figure")
		const imageElement = document.createElement("img")
		imageElement.alt = ""
		imageElement.role = "presentation"
		imageElement.src = data.url
		figureElement.appendChild(imageElement)

		if (data.caption) {
			const captionElement = document.createElement("figcaption")
			captionElement.innerHTML = data.caption
			captionElement.classList.add("p-4")
			figureElement.appendChild(captionElement)
		}

		this.contentElement.appendChild(figureElement)
	}

	get contentElement() {
		return this.dialogTarget.querySelector('[data-target="content"]')
	}
}
