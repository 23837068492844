import { Controller } from "stimulus"
import * as Utils from "../../utils/common"

export default class extends Controller {
	static targets = [ "template" ]

	show(event) {
		const link = event.currentTarget
		const consent = link.dataset.consent
		if (consent && !JSON.parse(consent)) return

		// because this probably will open the video in another tab, we cancel that behavior
		// and then we prepare the video dialog to include the video
		if (this.#setupDialogElement(link.href, link.dataset.title)) event.preventDefault()
	}

	#setupDialogElement(src, title) {
		const clone = this.templateTarget.content.cloneNode(true)

		// now we check if we have an `iframe` and we will only set this if we have an `iframe`
		// it's absence probably means that the user doesn't have the right cookie category activated
		const iframe = clone.querySelector("iframe")
		const embedURL = Utils.embedUrlForYouTube(src, { autoplay: 1 })
		if (!iframe || !embedURL) return false

		iframe.src = embedURL

		// if we have a title we will check if we have a heading element, if both are true
		// we set title as text of heading element
		if (title) {
			const heading = clone.querySelector('[data-dialog="heading"]')
			if (heading) heading.innerText = title
		}

		document.body.appendChild(clone)
		return true
	}
}
